<template>
    <el-dialog v-model="isDialogShow" width="480px" top="30vh" :close-on-click-modal="false" :destroy-on-close="true"
        @close="$emit('close')" custom-class="template-delete-dialog" :title="'删除我的模版中的“' + template.title + '”？'">
        <div class="text">删除后将无法使用该模版受众</div>
        <template #footer>
            <el-button @click="isDialogShow = false" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleDelete">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus';

const emit = defineEmits(['delete'])

const props = defineProps({
    template: {
        type: Object
    }
})

const isDialogShow = ref(true)

const handleDelete = () => {
    api.template.deletePresetTemplate(props.template.id).then(res => {
        ElMessage.success(`${props.template.title}删除成功`)
        isDialogShow.value = false
        emit('delete', props.template.id)
    })
}

</script>

<style lang="less">
.template-delete-dialog {
    .el-dialog__body {
        padding: 14px 20px 25px 20px;
    }

    .el-dialog__title {
        font-family: 'PingFang SC-Medium';
        color: #262626;
    }
}
</style>
<style lang="less" scoped>
.text {
    font-family: 'PingFang SC-Regular';
    font-weight: 400;
    font-size: 14px;
    color: #8C8C8C;
}
</style>
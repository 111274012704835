<template>
    <el-dialog v-model="isDialogShow" width="480px" top="30vh" :close-on-click-modal="false" @close="$emit('close')"
        custom-class="template-save-warn-dialog" title="将分享的模板保存为我的模板并命名">
        <div class="text">确定后保存至我的模板分类中，保存后即可查看分享模板详情</div>
        <el-input v-model="templateName" placeholder="请输入我的受众模板名称" clearable size="medium"></el-input>
        <template #footer>
            <el-button @click="isDialogShow = false" size="small">不保存</el-button>
            <el-button type="primary" size="small" @click="handleSave">保存为我的模板</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus';

const isDialogShow = ref(true)
const templateName = ref('')

const props = defineProps({
    template: {
        type: Object
    }
})
const emit = defineEmits(['close', 'save'])

const handleSave = () => {
    api.template.saveSharedPresetTemplate({
        t_id: props.template.t_id,
        title: templateName.value,
    }).then(res => {
        ElMessage.success(`${templateName.value}保存成功`)
        emit('save', res.id)
    })
}

onMounted(() => {
    templateName.value = props.template.title
})
</script>

<style lang="less">
.template-save-warn-dialog {
    .el-dialog__body {
        padding: 14px 20px 25px 20px;
    }

    .el-dialog__title {
        font-family: 'PingFang SC-Medium';
        color: #262626;
    }
}
</style>

<style lang="less" scoped>
.title {
    font-family: 'PingFang SC-Medium';
    font-weight: 500;
    font-size: 18px;
    color: #262626;
}

.text {
    font-family: 'PingFang SC-Regular';
    font-weight: 400;
    font-size: 14px;
    color: #8C8C8C;
    margin-bottom: 24px;
}
</style>
<template>
    <el-row class="ad-template">
        <el-col>
            <NoCompanyAttest v-if="$store.getters.userInfo.manage === 0" />
            <el-card :body-style="{ 'padding': '20px 40px 40px 40px', 'min-height': cardHeight + 'px' }" v-else>
                <div class="search-container">
                    <div class="search-input">
                        <el-carousel direction="vertical" indicator-position="none" class="search-swiper"
                            @change="swiperChange" @click="swiperClick" v-if="isShowSwiper"
                            :initial-index="placeholderIndex">
                            <el-carousel-item v-for="item in placeholderList" :key="item">
                                <span class="swiper-item">{{ item }}</span>
                            </el-carousel-item>
                        </el-carousel>
                        <el-input v-model="searchKeyWord" :placeholder="placeholder" @keyup.enter.native="handleSearch"
                            @blur="inputBlur" @input="handleSearch" ref="searchInputRef" clearable>
                            <template #suffix>
                                <img src="@/assets/images/business/search.svg" alt="" class="search-icon"
                                    @click="handleSearch">
                            </template>
                        </el-input>
                    </div>
                </div>
                <div class="tabs-container">
                    <div class="tab-item" v-for="item in categoryList" :key="item.id"
                        :class="{ 'active-tab': currentTabIndex === item.id }" @click="switchTab(item.id)">
                        <div>
                            <span>{{ item.name }}</span>
                            <span>{{ item.e_name }}</span>
                        </div>
                        <img src="@/assets/images/adTemplate/tab-bg.png" v-if="currentTabIndex === item.id">
                    </div>
                </div>
                <div class="template-container">
                    <el-row type="flex">
                        <div>{{ categoryList.filter(item => item.id === currentTabIndex)[0]?.name }}</div>
                        <div class="draft" v-if="AdTemplateDetail">
                            <img src="@/assets/images/adTemplate/info.svg" alt="">
                            <span>您有广告目前投放状态为草稿，<a @click="openDraft">去编辑发布</a></span>
                        </div>
                    </el-row>
                    <div class="template-list" v-if="templateList.length > 0">
                        <div class="template-item" v-for="item in templateList" :key="item.id"
                            :style="{ 'background-image': `url(${item.img})` }" @click="openTemplateDetail(item)">
                            <img src="@/assets/images/adTemplate/meta-icon.svg" alt="">
                            <img src="@/assets/images/adTemplate/share-icon.svg" alt="" class="item-share"
                                @click.stop="handleShare(item.id)"
                                v-if="categoryList.filter(item => item.id === currentTabIndex)[0]?.name !== '我的模板'">
                            <el-popover width="160" placement="right-start" :show-arrow="false"
                                popper-class="ad-template-popover" v-else>
                                <div style="display: flex;flex-direction: column;">
                                    <div class="item-menu"
                                        @click="selectDeleteTemplate = item; isRenameDialogShow = true">
                                        重命名</div>
                                    <div style="margin-top: 4px;" @click="handleShare(item.id, '我的模板')"
                                        class="item-menu">
                                        分享</div>
                                    <div style="margin-top: 4px;"
                                        @click="selectDeleteTemplate = item; isDeleteDialogShow = true"
                                        class="item-menu">
                                        删除</div>
                                </div>
                                <template #reference>
                                    <img src="@/assets/images/adTemplate/more-icon.svg" alt="" class="item-share">
                                </template>
                            </el-popover>
                            <div class="item-top">
                                <span :style="{ 'color': item.color }">{{ item.title }}</span>
                                <span :style="{ 'color': item.color }" class="desc">{{ item.describe }}</span>
                            </div>
                            <div class="item-box">
                                <div class="item-content"
                                    :style="{ justifyContent: item.type === 2 ? 'flex-end' : 'center' }">
                                    <div>
                                        <span>广告目标：{{ item.smart_promotion_type }}</span>
                                        <div class="recently-used" v-if="item.id == recentlyUsedId">
                                            <img src="@/assets/images/adTemplate/recently-used.svg" alt="">
                                            <span>上次使用</span>
                                        </div>
                                    </div>
                                    <span>成效目标：{{ item.bid_strategy }}</span>
                                    <span>国家地区：{{ item.region }}</span>
                                    <span v-if="item.type === 1">年龄范围：{{ item.age }}</span>
                                    <span v-if="item.type === 1 && item.c_id !== 6">受众人群：{{ item.audience }}</span>
                                    <el-tag size="medium" v-if="item.type === 2" effect="light"
                                        style="color: #2b82ff;margin-top: 8px;">进阶赋能型智能购物广告</el-tag>
                                </div>
                                <div class="item-mask" v-if="item.c_id !== 6">
                                    <div class="box">
                                        <div>广告消耗</div>
                                        <div>${{ item.spent }}K</div>
                                    </div>
                                    <div class="divider"></div>
                                    <div class="box">
                                        <div>广告点击率</div>
                                        <div>{{ item.click }}%</div>
                                    </div>
                                </div>
                                <div class="item-bottom">
                                    <div style="flex: 1;" v-show="item.c_id !== 6">
                                        <img src="@/assets/images/adTemplate/used-icon.svg" alt="">
                                        <span>{{ item.num }}人使用过</span>
                                    </div>
                                    <div class="item-button">启用</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <img src="@/assets/images/adTemplate/empty.svg" alt="">
                        <span
                            v-show="loading === false && categoryList.filter(item => item.id === currentTabIndex)[0]?.name === '我的模板'">暂无我的模板</span>
                        <span v-show="loading === true">模板加载中...</span>
                        <span
                            v-show="loading === false && categoryList.filter(item => item.id === currentTabIndex)[0]?.name !== '我的模板'">暂无模板</span>
                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>
    <!-- 分享模板弹窗 -->
    <ShareDialog v-if="isShareDialogShow" :id="selectTemplateId" :type="shareType" @close="isShareDialogShow = false" />
    <!-- 模板详情弹窗 -->
    <TemplateDetail v-if="isDetailDrawerShow" :id="selectTemplateId" :name="selectTemplateName"
        :audience="selectTemplateAudience" @close="closeTemplateDetail" @submit="e => handleSubmit(e)"
        @save="handleSave" />
    <!-- 广告创建状态弹窗 -->
    <CreateStatusDialog ref="createStatusDialogRef" v-if="isStatusDialogShow" @close="isStatusDialogShow = false"
        @edit="isStatusDialogShow = false; openDraft()" @save="isSaveTemplateDialogShow = true" />
    <!-- 删除我的模板弹窗 -->
    <DeleteDialog v-if="isDeleteDialogShow" :template="selectDeleteTemplate" @close="isDeleteDialogShow = false"
        @delete="id => handleDelete(id)" />
    <!-- 我的模板保存弹窗 -->
    <SaveTemplateDialog v-if="isSaveTemplateDialogShow" @close="isSaveTemplateDialogShow = false" @save="handleSave" />
    <!-- 我的模板重命名弹窗 -->
    <RenameTemplateDialog v-if="isRenameDialogShow" :template="selectDeleteTemplate" @close="isRenameDialogShow = false"
        @rename="name => handleRename(name)" />
    <!-- 分享的我的模板保存弹窗 -->
    <SaveWarnDialog v-if="isSaveWarnDialogShow" @close="isSaveWarnDialogShow = false" :template="selectDeleteTemplate"
        @save="id => saveShareTemplate(parseInt(id))" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, defineAsyncComponent } from 'vue';
import api from '@/request/api/ad'
import ShareDialog from './ShareDialog.vue';
import CreateStatusDialog from './CreateStatusDialog.vue';
import DeleteDialog from './DeleteDialog.vue';
import SaveTemplateDialog from './SaveTemplateDialog.vue';
import RenameTemplateDialog from './RenameTemplateDialog.vue';
import SaveWarnDialog from './SaveWarnDialog.vue';
import NoCompanyAttest from '@/components/base/NoCompanyAttest.vue'

const TemplateDetail = defineAsyncComponent(() =>
    import('./TemplateDetail.vue')
)

const { proxy } = getCurrentInstance()
const cardHeight = ref(window.innerHeight - 160)
const isShareDialogShow = ref(false)    // 是否显示分享模板弹窗
const isStatusDialogShow = ref(false) // 是否显示广告创建成功弹窗
const isDetailDrawerShow = ref(false) // 是否显示模板详情弹窗
const isDeleteDialogShow = ref(false) // 是否显示删除我的模板弹窗
const isSaveTemplateDialogShow = ref(false) // 是否显示我的模板保存弹窗
const isRenameDialogShow = ref(false) // 是否显示我的模板重命名弹窗
const isSaveWarnDialogShow = ref(false) // 是否显示分享我的模板保存弹窗
const createStatusDialogRef = ref(null)
const recentlyUsedId = ref(localStorage.getItem('AdTemplateRecentlyUsed') || null) // 上次使用的模板id
const AdTemplateDetail = ref(localStorage.getItem('AdTemplateDetail') || null) // 自动保存最后填写的模板详情表单
const searchInputRef = ref(null)
const searchKeyWord = ref('')
const isShowSwiper = ref(true)
const placeholder = ref('')
const placeholderIndex = ref(0)
const currentTabIndex = ref(1)
const selectTemplateId = ref(null)
const selectTemplateName = ref('')
const selectDeleteTemplate = ref({})
const selectTemplateAudience = ref(null)
const shareType = ref(null)
const placeholderList = ref(['食品饮料', '礼物&结婚', '美妆护肤', '宠物'])
const categoryList = ref([])
const templateList = ref([])
const hotTemplateList = ref([])
const loading = ref(false)

const swiperChange = (index) => {
    placeholderIndex.value = index
}

const swiperClick = () => {
    isShowSwiper.value = false
    searchInputRef.value.focus()
    placeholder.value = placeholderList.value[placeholderIndex.value]
}

const inputBlur = () => {
    if (!searchKeyWord.value) {
        placeholder.value = ''
        isShowSwiper.value = true
    }
    searchInputRef.value.blur()
}

const handleSearch = () => {
    if (window.pendingReqMap) {
        console.log('清空历史未结束请求')
        window.pendingReqMap.forEach((cancel) => {
            cancel();
        });
        window.pendingReqMap.clear()
    }
    templateList.value = []
    loading.value = true
    api.template.getTemplateList({
        keyword: searchKeyWord.value,
        c_id: currentTabIndex.value,
        batch_type: 0
    }).then(res => {
        templateList.value = res.data
        loading.value = false
    })
}

const switchTab = (id) => {
    currentTabIndex.value = id
    if (window.pendingReqMap) {
        console.log('清空历史未结束请求')
        window.pendingReqMap.forEach((cancel) => {
            cancel();
        });
        window.pendingReqMap.clear()
    }
    if (!searchKeyWord.value && id === 1 && hotTemplateList.value.length !== 0) {
        templateList.value = hotTemplateList.value
        return
    }
    templateList.value = []
    loading.value = true
    api.template.getTemplateList({
        keyword: searchKeyWord.value,
        c_id: currentTabIndex.value,
        batch_type: 0
    }).then(res => {
        templateList.value = res.data
        if (id === 1) hotTemplateList.value = res.data
        loading.value = false
    })
}

/** 分享模板 */
const handleShare = (id, type) => {
    selectTemplateId.value = id
    if (type) shareType.value = type
    console.log(id);
    isShareDialogShow.value = true
}

/** 删除我的模板 */
const handleDelete = (id) => {
    templateList.value = templateList.value.filter(item => item.id !== id)
}

/** 重命名我的模板 */
const handleRename = (name) => {
    templateList.value.forEach(item => {
        if (item.id === selectDeleteTemplate.value.id) {
            item.title = name
        }
    })
}

/** 保存模板后更新列表 */
const handleSave = () => {
    api.template.getTemplateList({
        c_id: currentTabIndex.value,
        batch_type: 0
    }).then(res => {
        templateList.value = res.data
    })
}

/** 保存分享模板 */
const saveShareTemplate = (id) => {
    isSaveWarnDialogShow.value = false
    currentTabIndex.value = 6
    templateList.value = []
    loading.value = true
    api.template.getTemplateList({
        c_id: 6,
        batch_type: 0
    }).then(res => {
        templateList.value = res.data
        loading.value = false
        const audience = templateList.value.filter(item => item.id === id)[0].audience
        openTemplateDetail(id, audience)
    })
}

const openTemplateDetail = (item) => {
    selectTemplateId.value = item.id
    selectTemplateName.value = item.title
    selectTemplateAudience.value = item.audience
    isDetailDrawerShow.value = true
    localStorage.setItem('AdTemplateRecentlyUsed', item.id)
    recentlyUsedId.value = item.id
}

const closeTemplateDetail = () => {
    isDetailDrawerShow.value = false
    if (localStorage.getItem('AdTemplateDetail')) {
        AdTemplateDetail.value = JSON.parse(localStorage.getItem('AdTemplateDetail'))
    } else {
        AdTemplateDetail.value = null
    }
}

/** 打开模板草稿 */
const openDraft = () => {
    selectTemplateId.value = JSON.parse(localStorage.getItem('AdTemplateDetail')).id
    selectTemplateName.value = JSON.parse(localStorage.getItem('AdTemplateDetail')).title
    selectTemplateAudience.value = localStorage.getItem('AdTemplateAudience')
    isDetailDrawerShow.value = true
    localStorage.setItem('AdTemplateRecentlyUsed', JSON.parse(localStorage.getItem('AdTemplateDetail')).id)
    recentlyUsedId.value = JSON.parse(localStorage.getItem('AdTemplateDetail')).id
}

/** 处理广告模板详情创建广告状态 */
const handleSubmit = (e) => {
    switch (e) {
        case 'success':
            createStatusDialogRef.value.handleSuccess()
            break
        case 'loading':
            isStatusDialogShow.value = true
            break
        default:
            createStatusDialogRef.value.handleFail(e)
            break
    }
}

const getCategoryList = () => {
    api.template.getTemplateCategory().then(res => {
        categoryList.value = res.data
    })
}

const getTemplateList = () => {
    loading.value = true
    api.template.getTemplateList({
        batch_type: 0
    }).then(res => {
        templateList.value = res.data
        hotTemplateList.value = res.data
        loading.value = false
    })
}

onMounted(() => {
    if (proxy.$store.getters.userInfo.manage === 0) return
    getCategoryList()
    getTemplateList()
    // 热门模板分享
    if (proxy.$route.query.id) {
        selectTemplateId.value = proxy.$route.query.id
        isDetailDrawerShow.value = true
        if (proxy.$route.query.source) {
            localStorage.setItem('TemplateSource', proxy.$route.query.source)
            api.template.handleEventTracking({ type: 5, source: localStorage.getItem('TemplateSource') })
        }
    }
    // 我的模板分享
    if (proxy.$route.query.preset) {
        let replacedStr = proxy.$route.query.preset.replace(/ /g, '+');
        api.template.handleShareUrl(replacedStr).then(res => {
            selectDeleteTemplate.value = {
                title: res.title,
                t_id: res.t_id,
            }
            isSaveWarnDialogShow.value = true
        })
    }
})
</script>

<style lang="less">
.ad-template-popover {
    .item-menu {
        font-family: 'PingFang SC-Regular';
        cursor: pointer;
        font-size: 14px;
        color: #595959;
        line-height: 22px;
        width: 42px;
        text-align: justify;
        text-align-last: justify;

        &:hover {
            color: #2b82ff;
        }
    }
}
</style>

<style lang="less" scoped>
.ad-template {
    .search-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../../../assets/images/adTemplate/search-bg.jpg') no-repeat;
        background-size: 100% 100%;
        min-height: 105px;
        height: 15vh;

        .search-input {
            position: relative;
            width: 64%;

            .search-swiper {
                height: 48px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 20px;
                z-index: 9;

                .swiper-item {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    line-height: 48px;
                    color: #8C8C8C;
                }
            }

            :deep(.el-input__inner) {
                height: 48px;
                border: none;
                padding-left: 20px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 22px;

                &::placeholder {
                    color: #8C8C8C;
                }
            }

            :deep(.el-input__suffix) {
                display: flex;
                justify-content: center;
                align-items: center;

                :deep(.el-input__suffix-inner) {
                    line-height: 40px;
                }
            }

            .search-icon {
                width: 20px;
                height: 20px;
                vertical-align: middle;
                margin-right: 16px;
                cursor: pointer;
            }
        }
    }

    .tabs-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 16px;

        .tab-item {
            box-sizing: border-box;
            width: calc((100% - 3 * 16px) / 4);
            height: 80px;
            padding: 0 20px;
            background: #F6F7F9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;

            &>div {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                padding: 16px 0;

                &>span:nth-child(1) {
                    color: #262626;
                    font-size: 14px;
                    font-family: PingFang SC-Medium;
                    font-weight: 500;
                }

                &>span:nth-child(2) {
                    color: #C6C6C6;
                    font-size: 12px;
                    font-family: PingFang SC-Regular;
                    font-weight: 400;
                }
            }

            img {
                width: 68px;
                height: 68px;
            }
        }

        .active-tab {
            background: #4AB8FF !important;

            span {
                color: #FFFFFF !important;
            }
        }
    }

    .template-container {
        margin-top: 40px;

        &>div:nth-child(1) {
            font-size: 20px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #262626;
            margin-bottom: 16px;
        }

        .draft {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            margin-left: 40px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            span {
                color: #595959;
            }

            a {
                color: #2B82FF;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        @media screen and (max-width: 2100px) {
            .template-list {
                gap: calc((100% - 5 * 270px) / 4) !important;

                .template-item {
                    width: 270px !important;
                }
            }
        }

        @media screen and (max-width: 1800px) {
            .template-list {
                gap: calc((100% - 4 * 270px) / 3) !important;

                .template-item {
                    width: 270px !important;
                }
            }
        }

        @media screen and (max-width: 1450px) {
            .template-list {
                gap: calc((100% - 3 * 270px) / 3) !important;

                .template-item {
                    width: 270px !important;
                }
            }
        }


        .template-list {
            display: flex;
            flex-wrap: wrap;
            gap: calc((100% - 6 * 280px) / 5);

            .template-item {
                position: relative;
                box-sizing: border-box;
                width: 270px;
                aspect-ratio: 53 / 58;
                padding: 20px 16px 16px 16px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
                border-radius: 12px 12px 12px 12px;
                margin-bottom: 20px;

                img {
                    width: 20px;
                    height: 16px;
                }

                .item-share {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    width: 16px;
                    height: 16px;
                    padding: 6px;
                    cursor: pointer;
                    display: none;
                }

                .item-top {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-top: 12px;

                    span {
                        font-size: 16px;
                        font-family: PingFang SC-Medium;
                        font-weight: 500;
                        line-height: 24px;
                        max-width: 145px;
                        word-wrap: break-word;
                    }

                    .desc {
                        font-size: 12px;
                    }
                }

                .item-box {
                    box-sizing: border-box;
                    width: 100%;
                    height: 60%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 8px 16px 16px 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .item-content {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;

                        span:not(.el-tag) {
                            font-size: 12px;
                            font-family: PingFang SC-Regular;
                            font-weight: 400;
                            color: #595959;
                            line-height: 20px;
                        }

                        &>div:first-child {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .recently-used {
                                span {
                                    line-height: 16px;
                                    color: #2B82FF;
                                }

                                img {
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 4px;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }

                    .item-mask {
                        display: none;
                        justify-content: center;
                        align-items: center;
                        margin-top: 8px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 120px;
                        background: #ffffff;

                        .box {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            &>div:nth-child(1) {
                                font-weight: 400;
                                font-size: 14px;
                                color: #595959;
                                line-height: 22px;
                            }

                            &>div:nth-child(2) {
                                font-family: PingFang SC-Medium;
                                font-weight: 500;
                                font-size: 16px;
                                color: #2B82FF;
                                line-height: 24px;
                            }
                        }

                        .divider {
                            width: 1px;
                            height: 54px;
                            background: #F0F0F0;
                            margin: 0 32px;
                        }
                    }

                    .item-bottom {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 4px;
                            vertical-align: middle;
                        }

                        span {
                            font-size: 12px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            color: #8C8C8C;
                            line-height: 20px;
                        }

                        .item-button {
                            width: 64px;
                            height: 32px;
                            border-radius: 100px;
                            border: 1px solid #2B82FF;
                            text-align: center;
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #2B82FF;
                            line-height: 32px;
                            cursor: pointer;
                        }
                    }
                }

                &:hover {
                    transform: translate(0, -8px);
                    box-shadow: 0px 4px 4px 0px rgba(43, 130, 255, 0.24);
                }

                &:hover .item-mask {
                    display: flex;
                }

                &:hover .item-share {
                    display: block;
                }

                &:hover .item-button {
                    background: #2B82FF;
                    border: none;
                    color: #FFFFFF !important;
                }
            }
        }

        .empty {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-family: 'PingFang SC-Regular';
                font-size: 16px;
                color: #8c8c8c;
            }
        }
    }

}
</style>
<template>
    <el-dialog v-model="isDialogShow" width="800px" top="22vh" :close-on-click-modal="false"
        custom-class="template-create-success-dialog" :destroy-on-close="true" @close="$emit('close')" center
        :show-close="status !== 'loading'">
        <div class="success-container" v-if="status === 'success'">
            <div class="title">广告创建成功!</div>
            <img src="@/assets/images/adTemplate/create-success.svg" alt="" />
            <span>广告创建成功，可点击按钮前往广告系列查看，或将刚刚创建的广告保存为我的模板，方便下次使用。</span>
            <div class="footer">
                <div class="button" @click="$emit('save')">保存为我的模板</div>
                <a class="button" @click="$router.push('/ad_series')">前往广告系列</a>
            </div>
        </div>
        <div class="fail-container" v-else-if="status === 'fail'">
            <div class="title">广告创建失败!</div>
            <img src="@/assets/images/adTemplate/create-fail.svg" alt="" />
            <span>{{ failureReason }}</span>
            <span>请点击按钮修改后重新创建广告。若多次尝试均无法创建成功请提交问题反馈--需求工单</span>
            <a class="button" @click="$emit('edit')">去修改</a>
        </div>
        <div class="loading-container" v-else>
            <div class="title">广告创建进度</div>
            <div class="progress">
                <div class="progress-bar" :style="{ 'width': `${progressValue}%` }">
                    <span class="progress-bar-value">{{ progressValue }}%</span>
                    <!-- <span class="progress-bar-circle" /> -->
                </div>
            </div>
            <div class="status">{{ progressValue < 35 ? '素材上传中' : '广告创建中' }}<span class="text-loading"></span></div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const emit = defineEmits(['close', 'save', 'edit']);
const status = ref('loading')
const isDialogShow = ref(true);
const progressValue = ref(10);
const failureReason = ref('');

const handleSuccess = () => {
    progressValue.value = 100;
    setTimeout(() => {
        status.value = 'success';
    }, 1000);
}

const handleFail = (reason) => {
    progressValue.value = 100;
    failureReason.value = reason;
    setTimeout(() => {
        status.value = 'fail';
    }, 1000);
}

const metaLink = computed(() => {
    const AdTemplateCustomAudience = JSON.parse(localStorage.getItem('AdTemplateCustomAudience'));
    return `https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${AdTemplateCustomAudience.account_id}`;
})

onMounted(() => {
    const timer = setInterval(() => {
        if (progressValue.value >= 95) {
            clearInterval(timer);
            return;
        }
        progressValue.value += 1;
    }, 200);
})

defineExpose({
    handleSuccess,
    handleFail
})
</script>

<style lang="less">
.template-create-success-dialog {
    .el-dialog__body {
        padding: 18px 40px 76px 40px;
    }
}
</style>

<style lang="less" scoped>
.success-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    .title {
        font-family: PingFang SC-Medium;
        font-weight: 500;
        font-size: 24px;
        color: #262626;
        line-height: 32px;
        margin-bottom: 40px;
    }

    span {
        width: 370px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #595959;
        margin-top: 40px;
    }

    .button {
        width: 130px;
        height: 40px;
        border-radius: 100px;
        background: #2B82FF;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        margin-top: 32px;
        text-decoration: none;
        cursor: pointer;
    }
}

.fail-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-family: PingFang SC-Medium;
        font-weight: 500;
        font-size: 24px;
        color: #262626;
        line-height: 32px;
        margin-bottom: 40px;
    }

    span {
        width: 485px;
        font-size: 16px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #595959;
        margin-top: 40px;
    }

    .button {
        width: 82px;
        height: 40px;
        border-radius: 100px;
        background: #2B82FF;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        margin-top: 32px;
        text-decoration: none;
    }
}

.loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-family: PingFang SC-Medium;
        font-weight: 500;
        font-size: 24px;
        color: #262626;
        line-height: 32px;
        margin-bottom: 156px;
    }

    .progress {
        width: 560px;
        height: 20px;
        background: #A9CCFF;
        border-radius: 100px;
        overflow: hidden;

        .progress-bar {
            width: 0;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background: #2B82FF;
            background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
            background-size: 40px 40px;
            transition: width .6s ease;
            border-radius: 100px;
            animation: progress-bar-anim 2s linear infinite;

            .progress-bar-circle {
                position: relative;
                width: 20px;
                height: 20px;
                background: rgb(197, 197, 197);
                border-radius: 50%;

                &::after {
                    content: '';
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    background: #ffffff;
                    opacity: 1;
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .progress-bar-value {
                font-size: 13px;
                font-weight: bold;
                color: #ffffff;
                margin-right: 5px;
            }
        }
    }

    .status {
        position: relative;
        width: 320px;
        text-align: center;
        font-family: PingFang SC-Regular;
        font-weight: 500;
        font-size: 20px;
        color: #2B82FF;
        margin-top: 40px;
        margin-bottom: 88px;
        padding-right: 32px;

        &:after {
            position: absolute;
            bottom: -4px;
            display: inline-block;
            animation: ellipsis 6s infinite;
            font-size: 32px;
            content: "";
        }

        @keyframes ellipsis {
            0% {
                content: "";
            }

            16% {
                content: ".";
            }

            32% {
                content: "..";
            }

            48% {
                content: "...";
            }

            64% {
                content: "....";
            }

            80% {
                content: ".....";
            }

            100% {
                content: "......";
            }
        }
    }

    @keyframes progress-bar-anim {
        from {
            background-position: 40px 0;
        }

        to {
            background-position: 0 0;
        }
    }
}
</style>
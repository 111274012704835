<template>
    <el-dialog v-model="isDialogShow" width="480px" top="30vh" :close-on-click-modal="false" :destroy-on-close="true"
        @close="$emit('close')" custom-class="template-rename-template-dialog" title="请输入我的受众模板名称">
        <div>
            <el-input v-model="templateName" placeholder="请输入我的受众模板名称" clearable size="medium"
                style="width: 400px;"></el-input>
        </div>
        <template #footer>
            <el-button @click="isDialogShow = false" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleRename">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus';

const isDialogShow = ref(true)
const templateName = ref('')

const props = defineProps({
    template: {
        type: Object
    }
})

const emit = defineEmits(['close', 'rename'])

const handleRename = () => {
    api.template.renameTemplate({
        id: props.template.id,
        title: templateName.value,
    }).then(res => {
        ElMessage.success('我的模板重命名成功')
        isDialogShow.value = false
        emit('rename', templateName.value)
    })
}

onMounted(() => {
    templateName.value = props.template.title
})
</script>

<style lang="less">
.template-rename-template-dialog {
    .el-dialog__body {
        padding: 20px 20px 25px 20px;
    }

    .el-dialog__title {
        font-family: 'PingFang SC-Medium';
        color: #262626;
    }
}
</style>

<style lang="less" scoped>
:deep(.el-input__inner) {
    color: #262626;
}
</style>
<template>
    <el-dialog v-model="isDialogShow" width="480px" top="30vh" :close-on-click-modal="false" :destroy-on-close="true"
        @close="$emit('close')" custom-class="template-save-template-dialog" title="请输入我的受众模板名称">
        <div>
            <el-input v-model="templateName" placeholder="请输入我的受众模板名称" clearable size="medium"
                style="width: 400px;"></el-input>
        </div>
        <!-- <div class="top" v-show="isRepeat"> -->
        <!-- <img src="@/assets/images/adTemplate/warning.svg" alt=""> -->
        <!-- <div class="title" style="margin-bottom: 0;">已有同名模板，是否进行覆盖?</div> -->
        <!-- </div> -->
        <template #footer>
            <el-button @click="isDialogShow = false" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleSave">确定</el-button>
            <!-- <el-button @click="isRepeat = false" size="small" v-show="isRepeat">否</el-button> -->
            <!-- <el-button type="primary" size="small" @click="handleSave" v-show="isRepeat">是</el-button> -->
        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus';

const isDialogShow = ref(true)
// const isRepeat = ref(false)
const templateName = ref('我的模板')
// const repeatId = ref(null)

const emit = defineEmits(['close', 'save'])

// const checkRepeat = () => {
//     if (!templateName.value) {
//         ElMessage.error('请输入预设受众模板名称')
//         return
//     }
//     const AdTemplateCustomAudience = JSON.parse(localStorage.getItem('AdTemplateCustomAudience'))
//     api.template.judgeTemplateName(templateName.value).then(res => {
//         if (res.id === 0) {
//             api.template.createPresetTemplate({
//                 t_id: AdTemplateCustomAudience.id,
//                 title: templateName.value,
//                 area: JSON.stringify(AdTemplateCustomAudience.region),
//                 age_min: AdTemplateCustomAudience.age_min + '',
//                 age_max: AdTemplateCustomAudience.age_max + '',
//                 interests: JSON.stringify(AdTemplateCustomAudience.audience_interest_words),
//                 sex: AdTemplateCustomAudience.sex
//             }).then(res => {
//                 ElMessage.success('我的模板保存成功')
//                 isDialogShow.value = false
//             })
//         } else {
//             repeatId.value = res.id
//             isRepeat.value = true
//         }
//     })
// }

const handleSave = () => {
    const AdTemplateCustomAudience = JSON.parse(localStorage.getItem('BatchAdTemplateCustomAudience'))
    api.template.createPresetTemplate({
        t_id: AdTemplateCustomAudience.id,
        title: templateName.value,
        area: JSON.stringify(AdTemplateCustomAudience.region),
        age_min: AdTemplateCustomAudience.age_min + '',
        age_max: AdTemplateCustomAudience.age_max + '',
        interests: JSON.stringify(AdTemplateCustomAudience.audience_interest_words),
        sex: AdTemplateCustomAudience.sex,
        account_info: JSON.stringify(AdTemplateCustomAudience.account_info),
        batch_type: 1
    }).then(res => {
        emit('save')
        ElMessage.success('我的模板保存成功')
        isDialogShow.value = false
    })
}
</script>

<style lang="less">
.template-save-template-dialog {
    .el-dialog__body {
        padding: 20px 20px 25px 20px;
    }

    .el-dialog__title {
        font-family: 'PingFang SC-Medium';
        color: #262626;
    }
}
</style>

<style lang="less" scoped>
:deep(.el-input__inner) {
    color: #262626;
}

.top {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    img {
        margin-right: 16px;
        vertical-align: middle;
    }

}
</style>
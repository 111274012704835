<template>
    <el-dialog v-model="isDialogShow" title="广告策略模版链接分享" width="480px" top="26vh" :close-on-click-modal="false"
        :destroy-on-close="true" @close="$emit('close')">
        <div class="tip">分享此链接,通过此链接注册登陆即可使用广告策略模版。</div>
        <div class="invite-content">
            <el-input size="small" disabled v-model="inviteUrl" />
        </div>
        <template #footer>
            <el-button @click="isDialogShow = false" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="copyUrl">复制链接</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import api from '@/request/api/ad'

const props = defineProps({
    id: {
        type: String
    },
    type: {
        type: String
    }
})
const { proxy } = getCurrentInstance()
const isDialogShow = ref(true)
const inviteUrl = ref('')

const copyUrl = () => {
    proxy.$copyText(inviteUrl.value).then(
        e => {
            proxy.$message.success('复制链接成功，立即分享链接！')
            api.template.handleEventTracking({ type: 4, source: localStorage.getItem('TemplateSource') })
        },
        e => {
            proxy.$message.error('复制失败！')
        }
    )
}

onMounted(() => {
    if (props.type === '我的模板') {
        api.template.getShareUrl(props.id).then(res => {
            inviteUrl.value = res.url
        })
    } else {
        inviteUrl.value = `${location.origin + location.pathname}?id=${props.id}`
    }
})
</script>

<style lang="less" scoped>
.tip {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8c8c;
    margin-bottom: 8px;
    margin-top: 10px;
}
</style>